import { ArrowNarrowRightIcon } from "@heroicons/react/outline"
import * as React from "react"
import Layout from "../../components/layout"
import Laptops from "../../images/hctv/ipad_mock.jpg"
import Iphones from "../../images/hctv/iphone_mock.jpg"

const HCTV = () => {
  return (
    <Layout>
      <div className="my-24 w-full md:w-4/5 mx-auto">
        <div className="flex items-baseline h-96 flex-col justify-center">
          <h1 className="text-5xl md:text-6xl font-black">
            <span className="highlight px-0.5">Hudson Community TV</span>
          </h1>
          <p className="text-lg mt-4">Live Streaming and Video Archive</p>
          <div className="flex h-8 mt-4">
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              web
            </p>
            <p className="rounded-full px-4 py-2 bg-ethan-blue text-white text-xs font-bold mr-2">
              UX Design
            </p>
          </div>
        </div>
        <div className="my-10 flex flex-col gap-10">
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">ABOUT</h4>
            <div>
              <p className="text-lg">
                A progressive web application to combine HCTV's live streaming
                and video archive is a simple and modern interface.
              </p>
              <div className="grid grid-cols-2 gap-4 mt-6">
                <p className="font-semibold">December 2021</p>
                <p>
                  GatsbyJS, Javascript, Algolia Search, HTML, CSS, TailwindCSS,
                  Vimeo API, NodeJS
                </p>
                <p className="font-semibold">Progressive Web App</p>
                <p>
                  <strong>Client:</strong> Hudson Community Television
                </p>
              </div>
            </div>
          </div>

          <a
            href="https://app.hudsoncommunity.tv"
            target="_blank" rel="noreferrer"
            className="p-10 bg-gray-100 rounded-lg text-xl font-bold text-center flex justify-center items-center group"
          >
            Visit the Site <span className="w-6 ml-2"><ArrowNarrowRightIcon/></span>
          </a>

          <img className="col-span-2 rounded-lg" src={Laptops} alt="mockup" />
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">OBJECTIVE</h4>
            <div>
              <p className="text-lg">
                The objective of this project was to create a single point of
                truth for all of HCTV's live and recorded programming. The
                current system that was in place took users to multiple websites
                and was housed in a hard to navigate website. This project's
                intent was to make this process easy, smooth, and user-friendly.
              </p>
            </div>
          </div>
          <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 bg-gray-100 rounded-lg">
            <h4 className="text-xl font-bold">SOLUTION</h4>
            <div>
              <p className="text-lg">
                The solution is a progressive web application that utilizes the
                Vimeo API to bring in the vast archive of videos and make them
                easily discoverable. It also brings in three live streams from
                HCTV's station that are easily found by users.
                <br />
                <br />
                It was found that a PWA would be the best solution for this
                problem because it can provide an app like feel and the ability
                for the user to install the app on some platforms.
              </p>
            </div>
          </div>
          <img className="col-span-2 rounded-lg" src={Iphones} alt="mockup" />
        </div>
      </div>
    </Layout>
  )
}

export default HCTV
